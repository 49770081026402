/* colors */
$clr-white: #FFFFFF;
$clr-black: #010101;
$clr-pink: #EF365C;
$clr-green: #00C7BA;
$clr-blue: #3A67E1;
$clr-red: #eb0000;
$clr-error: #D32F2F;
$clr-success: #0DAB03;
$clr-hyperlink: #1673FD;

/* text colors */
$clr-text-one: #3D3D3E; //#F3F3F3
$clr-text-two: #E9E7E7;
$clr-text-three: #4E4D4D; //#D4D4DA
$clr-text-four: #4E4D4D; //#D1D6D6
$clr-text-five: #4E4D4D; //#CCCCCC
$clr-text-six: #E7EBF2;
$clr-text-seven: #959191;
$clr-text-eight: #4E4D4D;
$clr-text-nine: #969595;
$clr-text-ten: #3D3D3E;

/* border colors */
$clr-border-one: #707070;
$clr-border-two: #8F8C8C;
$clr-border-three: #767474;
$clr-border-four: #797979;

/* background colors*/
$clr-bg-gray-dark: #373339;
$clr-bg-gray: #3d3d3e;
$clr-bg-main: #EDEDED; //#2A242E
$clr-bg-advisor: #FBFBFB; //#605F5F
$clr-bg-portfolio: #FBFBFB; //#434343
$clr-bg-button-disabled: #8b8b8b;
$clr-bg-dashboardmenu: #211F22;
$clr-bg-dashboard: #342F3B;
$clr-bg-scrollbar: #C2C2C2;
$clr-bg-scrollbar1: #A09D9D;
$clr-bg-black: #010101;
$clr-bg-white: #ffffff;

/* gradient color */
$clr-hr: linear-gradient(90deg, #EE365C 0%, 31.3793%, #1C71F8 62.7586%, 81.3793%, #ffffff 100%);
$clr-mr: linear-gradient(90deg, #1673FD 34.5751%, 51.5404%, #03C2B8 68.5057%, 84.2529%, #ffffff 100%);
$clr-lr: linear-gradient(90deg, #00C78B 0%, 14.023%, #03C2B7 28.046%, 64.023%, #ffffff 100%);

$clr-hrd: linear-gradient(90deg, #EE365C 17.8114%, 43.8882%, #1C71F8 69.9651%, 84.9825%, #f2f2f2 99.5%);
$clr-mrd: linear-gradient(90deg, #1673FD 8.8114%, 25.8882%, #03C2B8 69.9651%, 84.9825%, #f2f2f2 99.5%);
$clr-lrd: linear-gradient(90deg, #00C78B 20.8114%, 40.8882%, #03C2B7 70.046%, 85.023%, #f2f2f2 99.5%);

// $clr-portfoliodetails: transparent linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(22, 115, 253, 0.19) 100%);
$clr-portfoliodetails: transparent linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(182, 182, 182, 0.80) 100%);

/* box shadow colors */
$clr-box-shadow: rgba(0, 0, 0, 0.07) 0rem 0rem 0rem, rgba(0, 0, 0, 0.07) 0rem 0rem 0rem, rgba(0, 0, 0, 0.07) 0rem 0.25rem 0.25rem, rgba(0, 0, 0, 0.07) 0rem 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.07) 0rem 0rem 0.25rem;
$clr-top-box-shadow: 0px -8px 10px -12px rgba(0, 0, 0, 0.7);

/* hover colors */
$clr-box-hover: #f2f2f2; //#313131

/* font family */
$font-family-opensans: 'Open Sans', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;

/*font weight*/
$font-xlight:200;
$font-light:300;
$font-regular:400;
$font-medium:500;
$font-semibold:600;
$font-bold:700;
$font-xbold:800;
$font-black:900;



